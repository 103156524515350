const api_backend = 'https://api-mk.khuyenmaiapp.com';

// const api_backend = "http://localhost:5003";

const get_email = ({ username, type }) => {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  let raw = JSON.stringify({ username: username, type: type });
  let requestOptions = { method: "POST", headers: myHeaders, body: raw, redirect: "follow" };
  return fetch(api_backend + "/api/find?site=new88", requestOptions)
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
};

const get_pass = ({ username, code, type }) => {
  let myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  let raw = JSON.stringify({ username: username, code: code, type: type });
  let requestOptions = { method: "POST", headers: myHeaders, body: raw, redirect: "follow" };

  return fetch(api_backend + "/api/verify?site=new88", requestOptions)
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log("error", error);
    });
};
export { get_email, get_pass };
