import "./static/css/app.css";
import "./static/css/swiper.css";
import "swiper/css";
import { get_email, get_pass } from "./service/api";

import logo from "./static/media/logo.png";
import shield from "./static/media/shield.png";
import slogan from "./static/media/slogan.png";
import footer from "./static/media/bottom.png";
import loginPass from "./static/media/login-pass-btn.png";
import withdraw from "./static/media/withdraw-btn.png";
import noticeBg from "./static/media/notice.png";
import cskhBtn from "./static/media/cskh.png";

import arrow from "./static/media/arrowbtn.png";
import cu_1 from "./static/media/mk-costout/b1.png";
import cu_2 from "./static/media/mk-costout/b2.png";
import cu_3 from "./static/media/mk-costout/b3.png";
import cu_4 from "./static/media/mk-costout/b4.png";
import cu_5 from "./static/media/mk-costout/b5.png";
import cu_6 from "./static/media/mk-costout/b6.png";
import cu_7 from "./static/media/mk-costout/b7.png";

import lg_1 from "./static/media/mk-login/b1.png";
import lg_2 from "./static/media/mk-login/b2.png";
import lg_3 from "./static/media/mk-login/b3.png";
import lg_4 from "./static/media/mk-login/b4.png";
import lg_5 from "./static/media/mk-login/b5.png";
import lg_6 from "./static/media/mk-login/b6.png";
import lg_7 from "./static/media/mk-login/b7.png";

import { Navigation } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";
import Swal from "sweetalert2";
const App = () => {
  let submiting = false;
  const cskh_url = "https://new883.online";
  const home_url = "https://new883.online";
  async function handleInfo(event) {
    document.getElementById("loading_div").classList.remove("d-none");
    if (!submiting) {
      submiting = true;
      const username = document.getElementById("username").value;
      const type = document.getElementById("submit-btn").getAttribute("type-pass");
      if (event.target.getAttribute("state-id") === "user") {
        document.getElementsByClassName("notice-modal")[0].classList.remove("block-display");
        const res = await get_email({ username: username, type: type });
        if (res.status_code === 200) {
          Swal.fire({
            icon: "info",
            title: res?.title,
            allowOutsideClick: false,
            html: res?.mess,
          });
          document.getElementsByClassName("label")[0].classList.add("block-display");
          document.getElementById("account-label").innerHTML = document.getElementById("username").value;
          document.getElementById("username").classList.add("d-none");
          document.getElementById("code").classList.remove("d-none");
          event.target.setAttribute("state-id", "email");
        } else if (res.status_code === 300) {
          Swal.fire({
            icon: "info",
            title: res?.title,
            allowOutsideClick: false,
            html: res?.mess,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        } else {
          Swal.fire({
            icon: "error",
            title: res?.title,
            allowOutsideClick: false,
            html: res?.mess,
            footer: `<a style="color: #000" href="${cskh_url}">Chăm sóc khách hàng 24/7</a>`,
          });
        }
      } else {
        const code = document.getElementById("code").value;
        const res = await get_pass({ username: username, code: code, type: type });
        if (res.status_code === 200) {
          Swal.fire({
            icon: "success",
            title: res?.title,
            allowOutsideClick: false,
            confirmButtonText: "Trang chủ",
            html: res?.mess,
          }).then((result) => {
            if (result.isConfirmed) {
              navigator.clipboard.writeText(res.pas);
              window.location.replace(home_url);
            }
          });
        } else {
          Swal.fire({
            icon: "error",
            title: res?.title,
            allowOutsideClick: false,
            html: res?.mess,
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        }
      }
      submiting = false;
    }
    document.getElementById("loading_div").classList.add("d-none");
  }
  const showModal = (event) => {
    document.querySelector(".bg-input-wrapper").classList.remove("none-display");
    document.querySelector(".bg-input-wrapper").classList.add("flex-display");
    document.getElementsByClassName("wrapper")[0].classList.add("blur-display");
    if (event.target.getAttribute("state-id") === "login-pass") {
      document.getElementById("submit-btn").setAttribute("type-pass", "login-pass");
    } else if (event.target.getAttribute("state-id") === "withdraw-pass") {
      document.getElementById("submit-btn").setAttribute("type-pass", "withdraw-pass");
    }
  };

  const handleSubmit = (event) => {
    let username = document.getElementById("username").value;
    if (username === "") {
      document.getElementsByClassName("notice-modal")[0].classList.add("block-display");
    } else {
      if (event.target.getAttribute("type-pass") === "login-pass") {
        handleInfo(event);
      } else if (event.target.getAttribute("type-pass") === "withdraw-pass") {
        handleInfo(event);
      }
    }
  };

  const handleHideModal = (event) => {
    document.querySelector(".bg-input-wrapper").classList.add("none-display");
    document.querySelector(".bg-input-wrapper").classList.remove("flex-display");
    document.getElementsByClassName("notice-modal")[0].classList.remove("block-display");
    document.getElementsByClassName("wrapper")[0].classList.remove("blur-display");
    document.getElementsByClassName("label")[0].classList.remove("block-display");
    document.getElementsByClassName("label")[1].classList.remove("block-display");
    document.getElementById("username").classList.remove("d-none");
    document.getElementById("code").classList.add("d-none");
    document.getElementById("submit-btn").setAttribute("state-id", "user");
  };
  const handleHideHuongdan = (event) => {
    if (event.target === document.querySelector("#huongdan")) {
      document.querySelector("#huongdan").classList.add("none-display");
      document.querySelector("#huongdan").classList.remove("flex-display");
      document.getElementsByClassName("notice-modal")[0].classList.remove("block-display");
      document.getElementsByClassName("wrapper")[0].classList.remove("blur-display");
    }
  };
  const handleHideHuongdan2 = (event) => {
    if (event.target === document.querySelector("#huongdan2")) {
      document.querySelector("#huongdan2").classList.add("none-display");
      document.querySelector("#huongdan2").classList.remove("flex-display");
      document.getElementsByClassName("notice-modal")[0].classList.remove("block-display");
      document.getElementsByClassName("wrapper")[0].classList.remove("blur-display");
    }
  };
  const huongdan = () => {
    document.querySelector("#huongdan").classList.remove("none-display");
    document.querySelector("#huongdan").classList.add("flex-display");
    document.getElementsByClassName("wrapper")[0].classList.add("blur-display");
  };
  const huongdan2 = () => {
    document.querySelector("#huongdan2").classList.remove("none-display");
    document.querySelector("#huongdan2").classList.add("flex-display");
    document.getElementsByClassName("wrapper")[0].classList.add("blur-display");
  };
  return (
    <div id="main">
      <div className="wrapper">
        <div className="header">
          <img className="logo" src={logo} alt="" />
          <img className="slogan" src={slogan} alt="" />
        </div>
        <div className="phone-bg">
          <img className="shield y-axis" src={shield} alt="" />
        </div>
        <img className="login-pass-btn" src={loginPass} onClick={showModal} state-id="login-pass" alt="" />
        <button className="buttons btn-huongdan mb-15" onClick={huongdan}>
          Hướng dẫn
        </button>
        <img className="withdraw-btn" src={withdraw} onClick={showModal} state-id="withdraw-pass" alt="" />
        <button className="buttons btn-huongdan2 mb-10" onClick={huongdan2}>
          Hướng dẫn
        </button>
        <img className="notice" src={noticeBg} alt="" />
        <a href={cskh_url} className="cskh-btn-cont">
          <img className="cskh-btn" src={cskhBtn} alt="" />
        </a>
      </div>
      <img className="footer" src={footer} alt="" />
      <div className="bg-input-wrapper">
        <div className="input-wrapper">
          <div className="close-modal" onClick={handleHideModal}>
            <i class="fa-solid fa-xmark"></i>
          </div>
          <div className="info-modal">
            <p className="label">
              Tài khoản: <span id="account-label"></span>
            </p>
            <p className="label">
              <i>Mã xác nhận đã gửi tới email của bạn!</i>
            </p>
            <input className="input-modal" id="username" type="text" placeholder="Nhập tên tài khoản" />
            <input className="input-modal d-none" id="code" type="text" placeholder="Mã xác nhận Email" />
            <p className="notice-modal">Vui lòng nhập tên thông tin!</p>
            <div className="loading-div d-none" id="loading_div">
              <div className="loader"></div>
            </div>
            {/* <button className="buttons d-none" id="mxn-btn" onClick={getMXN} state-id="user">Lấy mã xác nhận</button> */}
            <button className="buttons" id="submit-btn" onClick={handleSubmit} state-id="user">
              XÁC NHẬN
            </button>
          </div>
        </div>
      </div>

      <div className="bg-input-wrapper2" id="huongdan" onClick={handleHideHuongdan}>
        <div className="input-wrapper2">
          <div className="info-modal">
            <div className="huongdan-title">
              <p className="label2 title">Hướng dẫn đổi mật khẩu đăng nhập</p>
            </div>
            <div className="content-huongdan">
              <div className="swiper-button bot-swiper-button-prev">
                <img src={arrow} alt="Button"></img>
              </div>
              <div className="swiper-button bot-swiper-button-next">
                <img src={arrow} alt="Button"></img>
              </div>
              <Swiper
                modules={[Navigation]}
                spaceBetween={50}
                slidesPerView={1}
                allowSlidePrev
                allowSlideNext
                scrollbar={{ draggable: true }}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                sx={{ width: "100%" }}
                navigation={{
                  nextEl: ".bot-swiper-button-next",
                  prevEl: ".bot-swiper-button-prev",
                  disabledClass: "swiper-button-disabled",
                }}
              >
                <SwiperSlide>
                  <img className="img-swiper" src={lg_1} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="img-swiper" src={lg_2} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="img-swiper" src={lg_3} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="img-swiper" src={lg_4} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="img-swiper" src={lg_5} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="img-swiper" src={lg_6} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="img-swiper" src={lg_7} alt="" />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-input-wrapper2" id="huongdan2" onClick={handleHideHuongdan2}>
        <div className="input-wrapper2">
          <div className="info-modal">
            <div className="huongdan-title">
              <p className="label2 title">Hướng dẫn đổi mật khẩu rút tiền</p>
            </div>
            <div className="content-huongdan">
              <div className="swiper-button bot-swiper-button-prev2">
                <img src={arrow} alt="Button"></img>
              </div>
              <div className="swiper-button bot-swiper-button-next2">
                <img src={arrow} alt="Button"></img>
              </div>
              <Swiper
                modules={[Navigation]}
                spaceBetween={50}
                slidesPerView={1}
                allowSlidePrev
                allowSlideNext
                scrollbar={{ draggable: true }}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                sx={{ width: "100%" }}
                navigation={{
                  nextEl: ".bot-swiper-button-next2",
                  prevEl: ".bot-swiper-button-prev2",
                  disabledClass: "swiper-button-disabled",
                }}
              >
                <SwiperSlide>
                  <img className="img-swiper" src={cu_1} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="img-swiper" src={cu_2} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="img-swiper" src={cu_3} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="img-swiper" src={cu_4} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="img-swiper" src={cu_5} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="img-swiper" src={cu_6} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img className="img-swiper" src={cu_7} alt="" />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
